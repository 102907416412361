import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LocationListGroup from "../components/locations/location-list-group";
import Seo from "../components/seo";
import Photo from "../images/iq/headshot.jpg";
import Logo from "../images/iq/logo-400.png";
import HowToDonateSection from "../sections/how-to-donate-section";
import QuoteSection from "../sections/quote-section";
import AboutSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
  <Seo title="iQ Credit Union" mdxType="Seo" />
  <QuoteSection logo={Logo} logoUrl={"https://www.iqcu.com/"} photo={Photo} text="iQ Credit Union strives each and every day to make our communities a special place we can all call home. Our partnership with the KGW Great Toy Drive accomplishes this for the most important members of our communities – the children. This huge collection event helps make the holidays special for thousands of kids in the metro area and now even more in SW Washington." name="Eric Petracca" title="iQ Credit Union CEO" mdxType="QuoteSection" />
  <LocationListGroup lists={[{
        title: false,
        url: "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/iq-locations.json",
        id: "iq-locations",
        note: true
      }]} headline="Visit Any iQ Location to Drop Off a Toy Today!" map={false} mdxType="LocationListGroup" />
  <HowToDonateSection mdxType="HowToDonateSection" />
  <VideoSection videos={[{
        file: "iq-toy-bot-30.mp4"
      }, {
        file: "iq-surprise-30.mp4"
      }, {
        file: "regence-60.mp4"
      }, {
        file: "fred-meyer-30.mp4"
      }, {
        file: "toyota-duplicator-30.mp4"
      }, {
        file: "toyota-surprise-30.mp4"
      }]} mdxType="VideoSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      